// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Route, Switch, Link, Routes, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { redirect } from "react-router-dom";

import Login from '../src/pages/login/Login';
import Dashboard from '../src/pages/dashboard/Dashboard';
import PageNotFound from './PageNotFound'
import Home from "./pages/Home";
import Main from "./components/layout/Main";
import Logout from './Logout'

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
    const isLoggedIn = localStorage.getItem('accessToken') ? true : false;
    const navigate = useNavigate();

  return (
    <div className="App">
            <div>
                <Routes>
                    <Route exact path="login" element={ <Login /> } />
                    <Route path="/" element={ isLoggedIn ?  <Main><Home /></Main>:  <Navigate to="/login" /> } />
                    <Route path="logout" element={ isLoggedIn ?  <Logout />:  <Navigate to="/login" /> } />
                    <Route path="*" element={ isLoggedIn ?  <PageNotFound />:  <Navigate to="/login" /> } />
                </Routes>
            </div>
    </div>
  );
}

export default App;
