import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { LOGO } from './constants/assets'
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item>
                <Box mb={2}>
                    <Typography variant="h1">404</Typography>
                </Box>
                <Box>
                    <Typography variant="h5">Page Not Found</Typography>
                </Box>
                <Box mt={3}>
                    <Button component={Link} to="/" variant="contained" color="primary">
                        Back to Home
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default PageNotFound;
