import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NAME, LOGO } from '../../constants/assets'
import { BASE_API_URL } from '../../constants/BASEURL'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                {NAME}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default function SignIn() {

    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const navigate = useNavigate();
    const handleEmailChange = (event) => {
        const inputValue = event.target.value;
        setEmail(inputValue);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailRegex.test(inputValue));
    };

    const handlePasswordChange = (event) => {
        const inputValue = event.target.value;
        setPassword(inputValue);
        setIsPasswordValid(inputValue.length >= 6);
    };

    const handleLoginSuccess = (accessToken) => {
        localStorage.setItem('accessToken', accessToken);
        setLoginSuccess(true);
        setTimeout(() => {
            setLoginSuccess(false);
        }, 3000);
    };

    const handleLoginError = (message) => {
        setLoginError(message);
        setTimeout(() => {
            setLoginError('');
        }, 3000); // Hide the error message after 3 seconds (adjust as needed)
    };

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
    };

    const handleSubmit = (event) => {

        if (!isEmailValid || !isPasswordValid) {
            // Handle the form submission when either email or password is invalid
            console.log('Invalid email or password');
            handleLoginError("Invalid email or password")
        }

        console.log(isEmailValid)
        console.log(isPasswordValid)

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });

        const username = data.get('email');
        const password = data.get('password');

        // Check if email and password are not empty
        if (!username.trim() && !password.trim()) {
            handleLoginError("Email and password are required.");
            return;
        }

        if (!username.trim() && password.trim()) {
            handleLoginError("Email is required.");
            return;
        }

        if (username.trim() && !password.trim()) {
            function isEmailValid(username) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(username);
            }
            if (isEmailValid(username)) {
                console.log("Valid email address.");
                handleLoginError("Password is required.");
                return;
            } else {
                console.log("Invalid email address.");
                return;
            }
        }

        if (isEmailValid && isPasswordValid) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic Og==");

            var raw = JSON.stringify({
                "username": username,
                "password": password,
                "async": false,
                "type": "ADMIN",
                "fcmToken": null
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };


            fetch(BASE_API_URL+"/auth/user/login", requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text();
                })
                .then(result => {
                    const response = JSON.parse(result);
                    const statusCode = response?.C;
                    console.log("Status Code from API:", statusCode);
                    console.log("Response:", response);

                    if (statusCode === 200) {
                        handleLoginSuccess(response.M.accessToken);
                        navigate('/');
                        console.log("Login Successful.");
                    } else if (statusCode === 401) {
                        let report = response.M.message
                        report = report.toString()
                        handleLoginError(report);
                        console.log("Username or Password incorrect");
                    }
                    else if (statusCode === 404) {
                        let report = response.D
                        report = report.toString()
                        handleLoginError(report);
                        console.log("Username or Password incorrect");
                    } else {
                        handleLoginError("Network error.");
                    }
                    //     NdLEST#24.
                })
                .catch(error => {
                    handleLoginError("Network error. Please check your connection.");
                    console.error('Error occurred:', error);
                })
                .finally(() => {
                    console.log("Fetch completed.");
                });
        }

    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main', height:100, width:100 }} src={ LOGO } >
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required ={true}
                            fullWidth
                            id="email"
                            label="Username"
                            name="email"
                            autoComplete="email"
                            type={"email"}
                            autoFocus
                            error={!isEmailValid}
                            helperText={!isEmailValid ? 'Invalid email' : ''}
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={!isPasswordValid} // Set the error property based on isPasswordValid state
                            helperText={!isPasswordValid ? 'Password must be at least 6 characters long' : ''}
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <br />

                <div id={'toast'} style={{ visibility: loginSuccess || loginError ? 'visible' : 'hidden' }}>
                    {loginSuccess && (
                        <Alert severity="success">Login Successful!</Alert>
                    )}
                    {loginError && (
                        <Alert severity="error">{loginError}</Alert>
                    )}
                </div>

                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}